import { getCrewMemberByPosition, getSpecialTitleByName, updateCrew } from './crew'
import { getRandomName, getRandomNameExcluding } from './helpers'
import settings from './settings'

export function awesomeCrew(currentShip) {
  const crewNames = []

  currentShip.crew_members.forEach((crew) => {
    const name = getRandomNameExcluding('awesomename', crewNames)
    crewNames.push(name)
    updateCrew(crew, {
      name: name
    })
  })
}

export function familyCrew(currentShip) {
  const crewNames = []

  currentShip.crew_members.forEach((crew) => {
    const name = getRandomNameExcluding('family', crewNames)
    crewNames.push(name)
    updateCrew(crew, {
      name: name,
      icon: 'cat-space',
      species: 'Space Cat',
      favorite_food: getRandomName('catfood')
    })
  })
}

export function seinfeldCrew(currentShip) {
  const crewNames = []

  currentShip.crew_members.forEach((crew) => {
    const name = getRandomNameExcluding('seinfeld', crewNames)
    const title = getSpecialTitleByName(name, crew.title)
    crewNames.push(name)
    updateCrew(crew, {
      name: name,
      favorite_food: getRandomName('seinfeldfood'),
      title: title
    })
  })
}

export function lostCrew(currentShip) {
  const crewNames = []

  currentShip.crew_members.forEach((crew) => {
    const name = getRandomNameExcluding('lost', crewNames)
    const title = getSpecialTitleByName(name, crew.title)
    const icon = title == 'Dog' ? 'dog' : crew.icon
    const species = title == 'Dog' ? 'Dog' : 'Human'
    crewNames.push(name)

    updateCrew(crew, {
      name: name,
      favorite_food: getRandomName('dharmafood'),
      title: title,
      species: species,
      icon: icon
    })
  })
}

export function bttfCrew(currentShip) {
  const crewNames = []

  currentShip.crew_members.forEach((crew) => {
    if (crew.position == settings.POSITION_SCIENTIST) {
      updateCrew(crew, {
        name: 'Dr. Brown'
      })
    } else {
      const name = getRandomNameExcluding('bttf', crewNames)
      const title = getSpecialTitleByName(name, crew.title)
      const icon = title == 'Dog' ? 'dog' : crew.icon
      const species = title == 'Dog' ? 'Dog' : 'Human'

      crewNames.push(name)

      updateCrew(crew, {
        name: name,
        favorite_food: getRandomName('food'),
        title: title,
        species: species,
        icon: icon
      })
    }
  })
}

export function starwarsCrew(currentShip) {
  const crewNames = []

  currentShip.crew_members.forEach((crew) => {
    const name = getRandomNameExcluding('starwars', crewNames)
    const title = getSpecialTitleByName(name, crew.title)
    const species = title.includes('Droid') ? settings.SPECIES_ROBOT : settings.SPECIES_HUMAN
    const favorite_food = species === settings.SPECIES_ROBOT ? getRandomName('robotfood') :  getRandomName('food')
    const icon = getStarWarCrewIcon(name, crew.icon)

    crewNames.push(name)

    updateCrew(crew, {
      name: name,
      favorite_food: favorite_food,
      title: title,
      species: species,
      icon: icon
    })
  })
}

function getStarWarCrewIcon(name, currentIcon) {
  switch(name) {
    case 'R2-D2':
      return 'robot-astromech'
    case 'Leia':
      return 'user-hair-buns'
    case 'Obi-Wan':
      return 'user-beard-bolt'
    case 'Luke':
      return 'user-hair-mullet'
    case 'C-3PO':
      return 'user-robot'
    default:
      return currentIcon
  }
}

export function contraCrew(currentShip) {
  const weaponsCrew = getCrewMemberByPosition(settings.POSITION_WEAPONS, currentShip)

  updateCrew(weaponsCrew, {
    name: getRandomName('konami'),
    icon: 'user-hair-mullet'
  })
}
