<template>

  <div class="">
    <div class="console with-border with-border-radius">
      <h3 class="event-title">
        <font-awesome-icon icon="computer-classic" /> Navigation Terminal
      </h3>

      <div class="row">
        <navigation-menu />
      </div>

      <div class="row">
        <div class="column-5">
          <div id="output">
            <div class="choices">
              <div><a href="#" @click.prevent="returnToBridge()">Return to Bridge</a></div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import GameMixin from '../../../mixins/GameMixin'
import NavigationMenu from '../../../components/Terminal/NavigationMenu.vue'

export default {
  name: "NavigationTerminal",

  components: {
    NavigationMenu
  },

  mixins: [
    GameMixin
  ],

  methods: {
    returnToBridge () {
      this.changeDisplay("bridge")
    }
  }
}
</script>
