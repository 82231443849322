<template>
  <div>
    <h3 class="terminal-heading">Crew Database</h3>

    <div v-if="this.selectedCrew == null">
      <ul class="fa-ul">
        <li v-for="crew in crewMembers" :key="crew.id">
          <span class="fa-li"><font-awesome-icon class="fa-fw" :icon="crew.icon" /></span>
          <a href="#" @click.prevent="viewCrew(crew)" class="terminal-menu">{{ crew.name }}</a> [{{ crew.status }}]
        </li>
      </ul>

      <a href="#" @click.prevent="$emit('switchView', 'menu')" class="terminal-menu">
        <i class="fas fa-left"></i> Return to Menu
      </a>
    </div>

    <div v-else class="column-12">
      <div class="row align-middle" style="padding-top: 10px;">
        <div>
          <font-awesome-icon class="fa-fw" :icon="selectedCrew.icon" style="font-size: 40px;" />
        </div>
        <div>
          <h2>{{ selectedCrew.name }}</h2>
          {{ title }}
        </div>
      </div>

      <div class="row">
        <p style="padding-bottom: 25px;">
          <i class="fas fa-caret-right"></i> Species: <span style="color: var(--purple);">{{ selectedCrew.species }}</span><br>
          <i class="fas fa-caret-right"></i> Home Planet: <span style="color: var(--purple);">{{ selectedCrew.home_planet }}K</span><br>
          <i class="fas fa-caret-right"></i> Status: <span style="color: var(--purple);">{{ selectedCrew.status }}</span><br>
          <i class="fas fa-caret-right"></i> Tasks Performed: <span style="color: var(--purple);">{{ selectedCrew.checks_performed }}</span><br>
          <i class="fas fa-caret-right"></i> Task Success Rate: <span style="color: var(--purple);">{{ calculateSuccessRate(selectedCrew) }}</span>
        </p>
      </div>

      <a href="#" @click.prevent="returnToList()" class="terminal-menu">
        <i class="fas fa-left"></i> Return to Crew List
      </a>

    </div>
  </div>
</template>

<script>
  import { getLivingCrewMembers, getDeadCrewMembers } from '../../utils/crew'
  import Ship from '../../store/models/Ship'

  export default {
    name: "CrewDatabase",

    data () {
      return {
        selectedCrew: null
      }
    },

    computed: {
      currentShip () {
        return Ship.query().with('crew_members').first()
      },

      crewMembers() {
        return getLivingCrewMembers(0, this.currentShip).concat(getDeadCrewMembers(this.currentShip))
      },

      title () {
        return this.selectedCrew.title ? this.selectedCrew.title : this.selectedCrew.position
      }
    },

    methods: {
      viewCrew (crew) {
        this.selectedCrew = crew
      },

      calculateSuccessRate(crew) {
        if (crew.checks_performed > 0) {
          const successRate = (crew.success_count / crew.checks_performed) * 100
          return `${successRate.toLocaleString()}%`
        } else {
          return 'N/A'
        }
      },

      returnToList () {
        this.selectedCrew = null
      }
    }
  }
</script>