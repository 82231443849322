export default {
  VERSION: '1.0.5',

  // STARTING SETTINGS
  STARTING_CONDITION: 'excellent',
  STARTING_MED_KITS: 0,
  STARTING_REPAIR_KITS: 0,
  STARTING_FOOD: 0,
  STARTING_ENGINE_EFFICIENCY: 0.4,
  STARTING_SPEED: 1,
  STARTING_YEAR: 2247,
  STARTING_DAY: 73,

  DAYS_PER_YEAR: 366,

  // DIFFICULTY SETTINGS
  TRAVEL_DISTANCE_EASY: 75000,
  TRAVEL_DISTANCE_NORMAL: 150000,
  TRAVEL_DISTANCE_HARD: 250000,
  TRAVEL_DISTANCE_EXPERT: 500000,
  TRAVEL_DISTANCE_FOREVER: 100000000,

  // DIFFICULTY NAMES - ON START SCREEN
  GAME_DIFFICULTY_EASY: 'Easy',
  GAME_DIFFICULTY_NORMAL: 'Normal',
  GAME_DIFFICULTY_HARD: 'Hard',
  GAME_DIFFICULTY_EXPERT: 'Nightmare',
  GAME_DIFFICULTY_FOREVER: 'Into the Black',

  // DETERMINES VALUE OF ITEM WHEN SOLD
  GAME_DIFFICULTY_SELL_MULTIPLIER_EASY: 0.5,
  GAME_DIFFICULTY_SELL_MULTIPLIER_NORMAL: 0.5,
  GAME_DIFFICULTY_SELL_MULTIPLIER_HARD: 0.4,
  GAME_DIFFICULTY_SELL_MULTIPLIER_EXPERT: 0.3,
  GAME_DIFFICULTY_SELL_MULTIPLIER_FOREVER: 0.4,

  // DISEASE MULTIPLERS
  DISEASE_FATALITY_RATE_MULTIPLIER_EASY: 0.8,
  DISEASE_FATALITY_RATE_MULTIPLIER_NORMAL: 1,
  DISEASE_FATALITY_RATE_MULTIPLIER_HARD: 1.4,
  DISEASE_FATALITY_RATE_MULTIPLIER_EXPERT: 1.8,
  DISEASE_FATALITY_RATE_MULTIPLIER_FOREVER: 1.2,

  // LOW HEALTH DEATH INCREASE
  LOW_HEALTH_DEATH_CHANCE_EASY: 8,
  LOW_HEALTH_DEATH_CHANCE_NORMAL: 10,
  LOW_HEALTH_DEATH_CHANCE_HARD: 20,
  LOW_HEALTH_DEATH_CHANCE_EXPERT: 25,
  LOW_HEALTH_DEATH_CHANCE_FOREVER: 20,

  // SPACEBALL INJURY CHANCE
  SPACEBALL_INJURY_PERCENTAGE_EASY: 20,
  SPACEBALL_INJURY_PERCENTAGE_NORMAL: 25,
  SPACEBALL_INJURY_PERCENTAGE_HARD: 35,
  SPACEBALL_INJURY_PERCENTAGE_EXPERT: 50,
  SPACEBALL_INJURY_PERCENTAGE_FOREVER: 35,

  // HIGHER NUMBER INCREASES DIFFICULTY
  // VALUE SUBSTRACTED FROM DIFFICULTY_CHECK_XXX TO INCREASE THE DIFFICULTY CHECK
  // EXAMPLE: DIFFICULTY_CHECK_BUILD_REPAIR_KIT - DIFFICULTY_ADJUSTMENT_HARD
  DIFFICULTY_ADJUSTMENT_EASY: 0,
  DIFFICULTY_ADJUSTMENT_NORMAL: 0,
  DIFFICULTY_ADJUSTMENT_HARD: 2,
  DIFFICULTY_ADJUSTMENT_EXPERT: 6,
  DIFFICULTY_ADJUSTMENT_FOREVER: 2,

  ITEM_BASE_PRICE_REPAIRKIT: 400,
  ITEM_BASE_PRICE_MEDKIT: 500,
  ITEM_BASE_PRICE_FOOD: 2, // per 1 food
  ITEM_BASE_PRICE_ERBIUM: 3000,
  ITEM_BASE_PRICE_ARTIFACT: 1000,
  ITEM_BASE_PRICE_AIRLOCK_REPAIR: 400,
  ITEM_BASE_PRICE_WASTE_DISPOSAL_REPAIR: 200,
  ITEM_BASE_PRICE_WEAPONS_SYSTEM_REPAIR: 500,
  ITEM_BASE_PRICE_NAVIGATION_SYSTEM_REPAIR: 500,
  ITEM_BASE_PRICE_CRATE: 250,

  STARTING_CREDITS_EASY: 12000,
  STARTING_CREDITS_NORMAL: 10000,
  STARTING_CREDITS_HARD: 10000,
  STARTING_CREDITS_EXPERT: 8000,
  STARTING_CREDITS_FOREVER: 10000,

  // CRATES IN HIDDEN ROOM
  STARTING_CRATES_EASY: 10,
  STARTING_CRATES_NORMAL: 5,
  STARTING_CRATES_HARD: 4,
  STARTING_CRATES_EXPERT: 2,
  STARTING_CRATES_FOREVER: 5,

  DISTANCE_PER_DAY: 1000,

  FOOD_RATION_FILLING: 3,
  FOOD_RATION_MEAGER: 2,
  FOOD_RATION_BAREBONES: 1,
  FOOD_RATION_NONE: 0,
  DEFAULT_FOOD_RATION_LEVEL: 3,

  STATUS_ALIVE: 'alive',
  STATUS_DEAD: 'dead',
  // STATUS_DISEASED: 'diseased', // NOT USED ANY LONGER - checks updated to use crew.has_disease
  STATUS_RAMPAGE: 'rampage',
  STATUS_PANIC: 'panic',

  STATUS_RAMPAGE_SKILL_PENALTY: -2,
  STATUS_PANIC_SKILL_PENALTY: -3,

  HUNGER_LEVEL_FULL: 'full',
  HUNGER_LEVEL_PECKISH: 'peckish',
  HUNGER_LEVEL_HUNGRY: 'hungry',
  HUNGER_LEVEL_STARVING: 'starving',

  // SPECIES
  SPECIES_HUMAN: 'human',
  SPECIES_GREYS: 'greys',
  SPECIES_ROBOT: 'robot',
  SPECIES_EYE: 'eye',
  SPECIES_AI: 'ai',
  SPECIES_RACCOON: 'raccoon',

  // CREW POSITIONS
  POSITIONS: ['Doctor', 'Mechanic', 'Weapons', 'Pilot', 'Cook', 'Navigator', 'Scientist'],

  POSITION_DOCTOR: 'Doctor',
  POSITION_MECHANIC: 'Mechanic',
  POSITION_WEAPONS: 'Weapons',
  POSITION_PILOT: 'Pilot',
  POSITION_COOK: 'Cook',
  POSITION_NAVIGATOR: 'Navigator',
  POSITION_SCIENTIST: 'Scientist',

  POSITION_ICON_DOCTOR: 'user-doctor',
  POSITION_ICON_MECHANIC: 'user-helmet-safety',
  POSITION_ICON_WEAPONS: 'user-bounty-hunter',
  POSITION_ICON_PILOT: 'user-astronaut',
  POSITION_ICON_COOK: 'user-chef',
  POSITION_ICON_NAVIGATOR: 'user-pilot',
  POSITION_ICON_SCIENTIST: 'user-visor',

  // CREW SPECIAL TITLES
  TITLE_MADSCIENTIST: 'Mad Scientist',
  TITLE_CHEF: 'Galactic Chef',
  TITLE_PILOT: 'Hot shot Pilot',

  // MORALE
  MORALE_DESCRIPTION_DISPAIR: 'extremely low',
  MORALE_DESCRIPTION_LOW: 'low',
  MORALE_DESCRIPTION_NEUTRAL: 'neutral',
  MORALE_DESCRIPTION_HIGH: 'high',
  MORALE_DESCRIPTION_EXCITED: 'extremely high',

  MAX_MORALE: 5,
  MIN_MORALE: -5,

  MAX_HEALTH: 100,
  MAX_ATTRIBUTE: 10,

  // SHIP SYSTEM STATUSES
  AIRLOCK_STATUS_NORMAL: 'online',
  AIRLOCK_STATUS_DAMAGED: 'malfunctioning',

  WASTE_DISPOSAL_STATUS_NORMAL: 'online',
  WASTE_DISPOSAL_STATUS_DAMAGED: 'malfunctioning',

  WEAPONS_SYSTEM_STATUS_NORMAL: 'online',
  WEAPONS_SYSTEM_STATUS_DAMAGED: 'malfunctioning',

  NAVIGATION_SYSTEM_STATUS_NORMAL: 'online',
  NAVIGATION_SYSTEM_STATUS_DAMAGED: 'malfunctioning',

  // DIFFICULTY CHECKS
  DIFFICULTY_CHECK_SCIENCE_CLONING: 20,
  DIFFICULTY_CHECK_SCIENCE_REPLICATE: 15,
  DIFFICULTY_CHECK_SCIENCE_SYNTHESIZE: 20,
  DIFFICULTY_CHECK_SCIENCE_SCAN: 20,
  DIFFICULTY_CHECK_COOKING_FEAST: 20,
  DIFFICULTY_CHECK_BUILD_REPAIR_KIT: 20,
  DIFFICULTY_CHECK_REPAIR_AIRLOCK: 20,
  DIFFICULTY_CHECK_REPAIR_WASTE_DISPOSAL: 20,
  DIFFICULTY_CHECK_REPAIR_WEAPONS_SYSTEM: 20,
  DIFFICULTY_CHECK_REPAIR_NAVIGATION_SYSTEM: 20,
  DIFFICULTY_CHECK_ALIEN_SYMBOLS: 20,

  AIRLOCK_MUTINY_THRESHOLD: 3,
  RAMPAGE_CHANCE: 50,
  MINOR_EVENT_CHANCE: 15,
  CANTINA_MINOR_EVENT_CHANCE: 25,

  JOB_MEDKIT: 'craftmedkit',
  JOB_FOOD: 'craftfood',
  JOB_CRYSTAL: 'scancrystal',
  JOB_REPAIRKIT: 'craftrepairkit',

  BONUS_CRYSTAL_DROP_RATE: 18,

  CRAFTING_TIME_MEDKIT: 5000,
  CRAFTING_TIME_FOOD: 5000,
  CRAFTING_TIME_CRYSTAL: 15000,
  CRAFTING_TIME_REPAIR_KIT: 10000,

  // COOLDOWNS
  COOK_COOLDOWN_DISTANCE: 8000,
  DOCTOR_COOLDOWN_DISTANCE: 8000,
  MECHANIC_COOLDOWN_DISTANCE: 8000,
  NAVIGATOR_COOLDOWN_DISTANCE: 8000,
  PILOT_COOLDOWN_DISTANCE: 8000,
  SCIENTIST_COOLDOWN_DISTANCE: 8000,
  WEAPONS_COOLDOWN_DISTANCE: 8000,
  SPACEBALL_COOLDOWN_DISTANCE: 10000,
  RAMPAGE_COOLDOWN_DISTANCE: 10000,

  FUEL_PRICE_PER_PERCENT: 10,
  WEAPON_PRICE_PER_PERCENT: 15,

  CLONING_CHANCE: 35,
  ROBOT_SCRAP_CHANCE: 40,
  BONUS_MATERIAL_ROBOT_SCRAP_CHANCE: 15,

  DRUNKENNESS_LEVEL_SOBER: 0,
  DRUNKENNESS_LEVEL_DRUNK: 50,
  DRUNKENNESS_LEVEL_BLACKOUT: 90,
  DRUNKENNESS_LEVEL_MAX: 100,

  DRINK_POTENCY_WEAK: 1.2,
  DRINK_POTENCY_MEDIUM: 1.5,
  DRINK_POTENCY_STRONG: 1.8,

  LOW_FUEL_EVENT_THRESHOLD: 5, // TRIGGERS LOW FUEL EVENT
  LOW_FUEL_EVENT_COOLDOWN_DISTANCE: 10000,

  // SHIP CONDITIONS
  SHIP_CONDITION_EXCELLENT: 'excellent',
  SHIP_CONDITION_GOOD: 'good',
  SHIP_CONDITION_POOR: 'poor',
  SHIP_CONDITION_CRITICAL: 'critical',

  // ACHIEVEMENTS
  CREDIT_ACHIEVEMENT_AMOUNT: 100000,
  FOOD_ACHIEVEMENT_AMOUNT: 30000,
  CRYSTAL_ACHIEVEMENT_AMOUNT: 30,
  ARTIFACT_ACHIEVEMENT_AMOUNT: 30,

  // CODES
  KONAMI_CODE: ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'KeyB', 'KeyA'],
  BACK_TO_THE_FUTURE_CODE: ['Digit1', 'Digit9', 'Digit8', 'Digit5'],
  LOST_CODE: ['KeyO', 'KeyC', 'KeyE', 'KeyA', 'KeyN', 'KeyI', 'KeyC'],
  LOST_NUMBERS: ['Digit4', 'Digit8', 'Digit1', 'Digit5', 'Digit1', 'Digit6', 'Digit2', 'Digit3', 'Digit4', 'Digit2'],
}
