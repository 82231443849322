import settings from '../utils/settings'
import Crew from '../store/models/Crew'
import { getRandomRecord, getRandomName, valueLimit, zeroLimit, getRandomNameExcluding } from '../utils/helpers'
import { checkDiseaseFatality, getHealthLossFromDisease, getIntialHealthLossFromDisease } from '../utils/disease'
import { addMessage, addStatusMessage } from './message'
import { updateGame } from './game'
import { getRandomPlanetName } from './planets'

function rollPrimaryAttribute() {
  // Primary Attributes are betwween 4 and 10 - roll 2d4 + 2
  return getRandomBetween(1, 4) + getRandomBetween(1, 4) + 2
}

function rollSecondaryAttribute() {
  // Secondary Attributes are betwween 4 and 8 - roll 2d4
  return getRandomBetween(1, 4) + getRandomBetween(1, 4)
}

function rollNormalAttribute() {
  // Normal Attributes are betwween 1 and 5 - roll 1d5
  return getRandomBetween(1, 5)
}

function skillIncrease(currentSkill) {
  const newSkill = currentSkill + getRandomBetween(1, 2)
  return newSkill > settings.MAX_ATTRIBUTE ? settings.MAX_ATTRIBUTE : newSkill
}

export function primarySkillByPosition(position) {
  switch (position) {
    case settings.POSITION_DOCTOR:
      return 'medical'

    case settings.POSITION_PILOT:
      return 'piloting'

    case settings.POSITION_NAVIGATOR:
      return 'navigation'

    case settings.POSITION_WEAPONS:
      return 'weapons'

    case settings.POSITION_SCIENTIST:
      return 'science'

    case settings.POSITION_COOK:
      return 'cooking'

    case settings.POSITION_MECHANIC:
      return 'mechanics'

    default:
      return ''
  }
}

export function increasePrimaryStat(crewMember) {
  var skillName = ''

  switch (crewMember.position) {
    case settings.POSITION_DOCTOR:
      skillName = 'medical'
      updateCrew(crewMember, { medical: skillIncrease(crewMember.medical) })
      break

    case settings.POSITION_PILOT:
      skillName = 'piloting'
      updateCrew(crewMember, { piloting: skillIncrease(crewMember.piloting) })
      break

    case settings.POSITION_NAVIGATOR:
      skillName = 'navigation'
      updateCrew(crewMember, { navigation: skillIncrease(crewMember.navigation) })
      break

    case settings.POSITION_WEAPONS:
      skillName = 'weapons'
      updateCrew(crewMember, { weapons: skillIncrease(crewMember.weapons) })
      break

    case settings.POSITION_SCIENTIST:
      skillName = 'science'
      updateCrew(crewMember, { science: skillIncrease(crewMember.science) })
      break

    case settings.POSITION_COOK:
      skillName = 'cooking'
      updateCrew(crewMember, { cooking: skillIncrease(crewMember.cooking) })
      break

    case settings.POSITION_MECHANIC:
      skillName = 'mechanics'
      updateCrew(crewMember, { mechanics: skillIncrease(crewMember.mechanics) })
      break
  }

  addMessage(`${crewMember.name}'s ${skillName} skill increased!`, 'gain-message')
}

function calculateCrewCost(primary, secondary) {
  return primary * 100 + secondary * 50 + Math.floor(Math.random() * 200)
}

function getRandomBetween(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min //The maximum is inclusive and the minimum is inclusive
}

function getBaseCrewData(shipId) {
  return {
    name: '',
    position: '',
    icon: '',
    piloting: rollNormalAttribute(),
    mechanics: rollNormalAttribute(),
    navigation: rollNormalAttribute(),
    medical: rollNormalAttribute(),
    weapons: rollNormalAttribute(),
    cooking: rollNormalAttribute(),
    science: rollNormalAttribute(),
    status: settings.STATUS_ALIVE,
    health: settings.MAX_HEALTH,
    morale: 0,
    xp: 0,
    level: 1,
    cost: 0,
    disease: '',
    home_planet: getRandomPlanetName(),
    favorite_food: getRandomName('food'),
    ship_id: shipId
  }
}

export function createCrewDoctor(shipId) {
  const data = getBaseCrewData(shipId)
  data.name = `Dr. ${getRandomName('doctorname')}`
  data.position = settings.POSITION_DOCTOR
  data.icon = settings.POSITION_ICON_DOCTOR
  data.medical = rollPrimaryAttribute()
  data.science = rollSecondaryAttribute()
  data.cost = calculateCrewCost(data.medical, data.science)
  return data
}

export function createCrewMechanic(shipId) {
  const data = getBaseCrewData(shipId)
  data.name = getRandomName('mechanicname')
  data.position = settings.POSITION_MECHANIC
  data.icon = settings.POSITION_ICON_MECHANIC
  data.mechanics = rollPrimaryAttribute()
  data.piloting = rollSecondaryAttribute()
  data.cost = calculateCrewCost(data.mechanics, data.piloting)
  return data
}

export function createCrewPilot(shipId) {
  const data = getBaseCrewData(shipId)
  data.name = getRandomName('pilotname')
  data.position = settings.POSITION_PILOT
  data.icon = settings.POSITION_ICON_PILOT
  data.piloting = rollPrimaryAttribute()
  data.navigation = rollSecondaryAttribute()
  data.cost = calculateCrewCost(data.piloting, data.navigation)
  return data
}

export function createCrewWeapons(shipId) {
  const data = getBaseCrewData(shipId)
  data.name = getRandomName('weaponsname')
  data.position = settings.POSITION_WEAPONS
  data.icon = settings.POSITION_ICON_WEAPONS
  data.weapons = rollPrimaryAttribute()
  data.medical = rollSecondaryAttribute()
  data.cost = calculateCrewCost(data.weapons, data.medical)
  return data
}

export function createCrewCook(shipId) {
  const data = getBaseCrewData(shipId)
  data.name = getRandomName('cookname')
  data.position = settings.POSITION_COOK
  data.icon = settings.POSITION_ICON_COOK
  data.cooking = rollPrimaryAttribute()
  data.science = rollSecondaryAttribute()
  data.cost = calculateCrewCost(data.cooking, data.science)
  return data
}

export function createCrewScientist(shipId) {
  const data = getBaseCrewData(shipId)
  data.name = getRandomName('scientistname')
  data.position = settings.POSITION_SCIENTIST
  data.icon = settings.POSITION_ICON_SCIENTIST
  data.science = rollPrimaryAttribute()
  data.weapons = rollSecondaryAttribute()
  data.cost = calculateCrewCost(data.science, data.weapons)
  return data
}

export function createCrewNavigator(shipId) {
  const data = getBaseCrewData(shipId)
  data.name = getRandomName('navigatorname')
  data.position = settings.POSITION_NAVIGATOR
  data.icon = settings.POSITION_ICON_NAVIGATOR
  data.navigation = rollPrimaryAttribute()
  data.piloting = rollSecondaryAttribute()
  data.cost = calculateCrewCost(data.navigation, data.piloting)
  return data
}

export function cloneCrewMember(crew, shipId) {
  const data = {
    name: `${crew.name} ${getRandomName('clonesuffix')}`,
    position: crew.position,
    icon: crew.icon,
    piloting: crew.piloting,
    mechanics: crew.navigation,
    navigation: crew.navigation,
    medical: crew.medical,
    weapons: crew.weapons,
    cooking: crew.cooking,
    science: crew.science,
    status: settings.STATUS_ALIVE,
    health: 100,
    morale: 0,
    xp: 0,
    level: 1,
    cost: 0,
    disease: '',
    home_planet: crew.home_planet,
    favorite_food: getRandomName('food'),
    ship_id: shipId
  }

  return data
}

export function hireCrew(shipId) {
  Crew.deleteAll()

  addCrew(createCrewCook(shipId))
  addCrew(createCrewPilot(shipId))
  addCrew(createCrewScientist(shipId))
  addCrew(createCrewMechanic(shipId))
  addCrew(createCrewDoctor(shipId))
  addCrew(createCrewWeapons(shipId))
  addCrew(createCrewNavigator(shipId))
}

export function generateCrewForHire() {
  var i = 0
  for (i = 0; i < 3; i++) {
    addCrew(createCrewCook(0))
    addCrew(createCrewDoctor(0))
    addCrew(createCrewMechanic(0))
    addCrew(createCrewNavigator(0))
    addCrew(createCrewPilot(0))
    addCrew(createCrewScientist(0))
    addCrew(createCrewWeapons(0))
  }
}

export function addCrew(data) {
  Crew.insert({
    data: data
  })
}

export async function updateCrew(crew, data) {
  // Returns a promise - if you need the updated crew record right away use .then((c) => { return c })
  return await Crew.update({
    where: crew.id,
    data: data
  })
}

export function getCrewMemberByPosition(position, ship) {
  const crewMember = Crew.query()
    .where((crew) => {
      return crew.position == position && crew.status != settings.STATUS_DEAD && crew.ship_id == ship.id
    })
    .first()

  return crewMember
}

export function getCrewMemberById(id) {
  return Crew.query().whereId(id).first()
}

export function getLivingCrewMembers(excluding_crew_id, ship) {
  const crewMembers = Crew.query()
    .with('disease')
    .where((crew) => {
      return crew.status != settings.STATUS_DEAD && crew.ship_id == ship.id && crew.id != excluding_crew_id
    })
    .all()

  return crewMembers
}

export function getRandomRampagingCrewMember(ship) {
  return getRandomRecord(getRampagingCrewMembers(ship))
}

export function getRampagingCrewMembers(ship) {
  const crewMembers = Crew.query()
    .with('disease')
    .where((crew) => {
      return crew.status === settings.STATUS_RAMPAGE && crew.ship_id == ship.id
    })
    .all()

  return crewMembers
}

export function getSickCrewMembers(ship) {
  const crewMembers = Crew.query()
    .with('disease')
    .where((crew) => {
      return crew.status != settings.STATUS_DEAD && crew.ship_id == ship.id && (crew.health < settings.MAX_HEALTH || crew.has_disease)
    })
    .all()

  return crewMembers
}

export function getStarvingCrewMembers(ship) {
  const crewMembers = Crew.query()
    .with('disease')
    .where((crew) => {
      return crew.status != settings.STATUS_DEAD && crew.hunger == settings.HUNGER_LEVEL_STARVING && crew.ship_id == ship.id
    })
    .all()

  return crewMembers
}

export function getHealthyCrewMembers(excluding_crew_id, ship) {
  const crewMembers = Crew.query()
    .with('disease')
    .where((crew) => {
      return crew.status != settings.STATUS_DEAD && crew.has_disease == false && crew.ship_id == ship.id && crew.id != excluding_crew_id
    })
    .all()

  return crewMembers
}

export function getDeadCrewMembers(ship) {
  const crewMembers = Crew.query()
    .where((crew) => {
      return crew.status === settings.STATUS_DEAD && crew.ship_id == ship.id
    })
    .all()

  return crewMembers
}

export function getCrewMembersBySpecies(ship, species) {
  const crewMembers = Crew.query()
    .with('disease')
    .where((crew) => {
      return crew.status != settings.STATUS_DEAD && crew.ship_id == ship.id && crew.species == species
    })
    .all()

  return crewMembers
}

export function getCrewForHire(position) {
  const crewMembers = Crew.query()
    .where((crew) => {
      return crew.status != settings.STATUS_DEAD && crew.ship_id == 0 && crew.position == position
    })
    .all()

  return crewMembers
}

export function getMissingCrewTypes(ship) {
  const crew_members = Crew.query()
    .where((crew) => {
      return crew.status != settings.STATUS_DEAD && crew.ship_id == ship.id
    })
    .all()

  const crewTypes = crew_members.map((crew) => {
    return crew.position
  })

  const missingCrewTypes = settings.POSITIONS.reduce(function (result, value) {
    if (!crewTypes.includes(value)) {
      result.push(value)
    }
    return result
  }, [])

  return missingCrewTypes
}

export function getRandomCrewMember(ship) {
  return getRandomRecord(getLivingCrewMembers(0, ship))
}

export function getNumberOfRandomCrewMember(ship, total) {
  const allCrew = getLivingCrewMembers(0, ship)

  if (allCrew.length <= total) {
    return allCrew
  } else {
    const crewMembers = []
    let selectedCrew = null

    do {
      selectedCrew = getRandomRecord(allCrew)

      if (!crewMembers.includes(selectedCrew)) {
        crewMembers.push(selectedCrew)
      }
    } while (crewMembers.length < total)

    return crewMembers
  }
}

export function getRandomCrewMemberBySpecies(ship, species) {
  return getRandomRecord(getCrewMembersBySpecies(ship, species))
}

export function getRandomCrewMemberExcluding(crew_id, ship) {
  return getRandomRecord(getLivingCrewMembers(crew_id, ship))
}

export function getRandomHealthyCrewMemberExcluding(crew_id, ship) {
  return getRandomRecord(getHealthyCrewMembers(crew_id, ship))
}

export function increaseDrunkenness(crew, drinkPotency) {
  const drunkenness = valueLimit(crew.drunkenness + Math.floor(Math.random() * 50 * drinkPotency))
  updateCrew(crew, { drunkenness: drunkenness })

  if (drunkenness >= settings.DRUNKENNESS_LEVEL_DRUNK) {
    addMessage(`${crew.name} had too much to drink.`, 'lose-message')
  }
}

export function updateAllCrewDrunkenness(ship, drinkPotency) {
  getLivingCrewMembers(0, ship).forEach((crew) => {
    increaseDrunkenness(crew, drinkPotency)
  })
}

export function updateCrewDrunkenness(crew) {
  // reduce drunkenness by 3 to 8 points
  const drunkenness = zeroLimit(crew.drunkenness - Math.floor(Math.random() * 6 + 2))
  updateCrew(crew, { drunkenness: drunkenness })
}

export function updateCrewDisease(crew, game) {
  if (crew.has_disease) {
    const remainingDays = crew.disease_duration - 1

    if (remainingDays == 0) {
      updateCrew(crew, { has_disease: false, disease_id: 0, disease_duration: 0 })
      addStatusMessage(`${crew.name} has recovered from their illness.`)
    } else {
      if (checkDiseaseFatality(crew, game)) {
        crewDeath(crew, game)
        addStatusMessage(`${crew.name} died from  ${crew.disease.name}.`)
      } else {
        updateCrew(crew, { health: getHealthLossFromDisease(crew), disease_duration: remainingDays })
      }
    }
  }
}

export function updateCrewMorale(crew, change) {
  if (crew.status == settings.STATUS_PANIC && change > 0) {
    // Crew cannot gain morale with status panic
    change = 0
  }

  let morale = crew.morale + change
  morale = morale < settings.MIN_MORALE ? settings.MIN_MORALE : morale
  morale = morale > settings.MAX_MORALE ? settings.MAX_MORALE : morale

  if (crew.morale == morale) {
    return ''
  } else {
    updateCrew(crew, { morale: morale })
    return `${crew.name}'s morale ${change > 0 ? 'increased' : 'decreased'}.`
  }
}

export function smallMoraleBoost(ship) {
  // Boost amount is 1 or 2
  const boostAmount = Math.floor(Math.random() * 3) == 3 ? 2 : 1

  getLivingCrewMembers(0, ship).forEach((crew) => {
    const result = updateCrewMorale(crew, boostAmount)

    if (result != '') {
      addMessage(result, '')
    }
  })
}

export function largeMoraleBoost(ship) {
  // Boost amount is between 2 and 5
  const boostAmount = Math.floor(Math.random() * 4) + 1

  getLivingCrewMembers(0, ship).forEach((crew) => {
    const result = updateCrewMorale(crew, boostAmount)

    if (result != '') {
      addMessage(result, '')
    }
  })
}

export function updateCrewHealth(crew, change) {
  const health = valueLimit(crew.health + change)

  if (crew.health == health) {
    return ''
  } else {
    updateCrew(crew, { health: health })
    return `${crew.name}'s health ${change > 0 ? 'increased' : 'decreased'}.`
  }
}

export function crewDeath(crew, game) {
  updateCrew(crew, { status: settings.STATUS_DEAD, health: 0, icon: 'skull' })
  updateGame(game, { crew_death_count: game.crew_death_count + 1 })
}

export function calculatedDeathChance(game) {
  switch (game.difficulty) {
    case settings.GAME_DIFFICULTY_EASY:
      return settings.LOW_HEALTH_DEATH_CHANCE_EASY
    case settings.GAME_DIFFICULTY_NORMAL:
      return settings.LOW_HEALTH_DEATH_CHANCE_NORMAL
    case settings.GAME_DIFFICULTY_HARD:
      return settings.LOW_HEALTH_DEATH_CHANCE_HARD
    case settings.GAME_DIFFICULTY_EXPERT:
      return settings.LOW_HEALTH_DEATH_CHANCE_EXPERT
    case settings.GAME_DIFFICULTY_FOREVER:
      return settings.LOW_HEALTH_DEATH_CHANCE_FOREVER
  }
}

export function deathByPoorHealth(game) {
  const deathChance = calculatedDeathChance(game)
  return Math.random() * 100 < deathChance
}

export function updateCrewHunger(crew, foodConsumed, game) {
  let health = crew.health

  switch (foodConsumed) {
    case settings.FOOD_RATION_FILLING:
      feedCrew(crew, settings.HUNGER_LEVEL_FULL, foodConsumed)

      // Being full can cause a slight gain in health
      if (crew.has_disease == false && crew.health < settings.MAX_HEALTH) {
        if (Math.floor(Math.random() * 100) > 10 + crew.morale) {
          health = crew.health + Math.floor(Math.random() * 5)
        }
      }
      break

    case settings.FOOD_RATION_MEAGER:
      feedCrew(crew, settings.HUNGER_LEVEL_PECKISH, foodConsumed)
      break

    case settings.FOOD_RATION_BAREBONES:
      feedCrew(crew, settings.HUNGER_LEVEL_HUNGRY, foodConsumed)

      // Being hungry can cause a loss of morale
      if (Math.floor(Math.random() * 100) > crew.health + crew.morale) {
        updateCrewMorale(crew, -1)
      }

      break

    case settings.FOOD_RATION_NONE:
      feedCrew(crew, settings.HUNGER_LEVEL_STARVING, foodConsumed)

      // Starving can cause a loss of morale
      if (Math.floor(Math.random() * 100) > crew.health - 10) {
        updateCrewMorale(crew, -1)
      }

      // Starving can cause loss of health and even death
      health = crew.health - Math.floor(Math.random() * 5)
      if (health <= 0) {
        if (!deathByPoorHealth(game)) {
          health = 5
        }
      }
      break
  }

  if (health > 0) {
    updateCrew(crew, { health: health })
  } else {
    crewDeath(crew, game)
    addStatusMessage(`${crew.name} died.`)
  }
}

export function feedCrew(crew, hungerLevel, foodConsumed) {
  if (foodConsumed == 0) {
    updateCrew(crew, { hunger: hungerLevel, days_since_last_meal: crew.days_since_last_meal + 1 })
  } else {
    updateCrew(crew, { hunger: hungerLevel, days_since_last_meal: 0 })
  }
}

export function setCrewMurderousRampage(crew) {
  updateCrew(crew, {
    status: settings.STATUS_RAMPAGE
  })
}

export function endCrewMurderousRampage(crew) {
  updateCrew(crew, {
    status: settings.STATUS_ALIVE,
    morale: -3
  })
}

export function setCrewPanic(crew) {
  updateCrew(crew, {
    status: settings.STATUS_PANIC
  })
}

export function endCrewPanic(crew) {
  updateCrew(crew, {
    status: settings.STATUS_ALIVE
  })
}

export function setCrewDisease(crew, disease, difficulty) {
  updateCrew(crew, {
    has_disease: true,
    disease_id: disease.id,
    disease_duration: disease.duration,
    health: getIntialHealthLossFromDisease(crew, difficulty)
  })
}

export function cureCrewDisease(crew) {
  updateCrew(crew, {
    has_disease: false,
    disease_id: null,
    disease_duration: 0
  })
}

export function getFeelingDescription(healthGained) {
  switch (true) {
    case healthGained > 30:
      return 'much much better'
    case healthGained > 20:
      return 'much better'
    case healthGained > 10:
      return 'better'
    default:
      return 'a little better'
  }
}

export function changeToMadScientist(scientist) {
  updateCrew(scientist, { title: 'Mad Scientist' })
  addMessage(`${scientist.name} has gone mad!`)
  increasePrimaryStat(scientist)
  increasePrimaryStat(scientist)
}

export function transformCrewIntoMonkey(crew) {
  updateCrew(crew, {
    icon: 'monkey',
    favorite_food: 'Banana Moon Pie'
  })
}

export function transformCrewIntoCat(crew) {
  return updateCrew(crew, {
    icon: 'cat-space',
    favorite_food: getRandomName('catfood')
  })
}

export function transformCrewIntoCrab(crew) {
  return updateCrew(crew, {
    icon: 'crab',
    favorite_food: getRandomName('crabfood')
  })
}

export function transformCrewIntoRobot(crew) {
  const data = {
    name: getRandomName('robotname'),
    icon: 'user-robot',
    favorite_food: getRandomName('robotfood'),
    species: settings.SPECIES_ROBOT
  }

  return updateCrew(crew, data)
}

export function transformCrewIntoEvilEye(crew) {
  const data = {
    name: getRandomName('alienname'),
    icon: 'eye-evil',
    favorite_food: getRandomName('eyefood'),
    species: settings.SPECIES_EYE,
    status: settings.STATUS_ALIVE,
    has_disease: false,
    disease_id: null,
    disease_duration: 0,
    health: 100,
    morale: 0
  }

  return updateCrew(crew, data)
}

export function transformCrewIntoRaccoon(crew) {
  const data = {
    name: getRandomName('raccoon'),
    icon: 'raccoon',
    favorite_food: getRandomName('raccoonfood'),
    species: settings.SPECIES_RACCOON,
    status: settings.STATUS_ALIVE
  }

  return updateCrew(crew, data)
}

export function transformCrewIntoAI(crew) {
  const data = {
    name: getRandomName('ainame'),
    icon: 'brain-circuit',
    favorite_food: getRandomName('aifood'),
    species: settings.SPECIES_AI,
    status: settings.STATUS_ALIVE,
    has_disease: false,
    disease_id: null,
    disease_duration: 0,
    health: 100,
    morale: 0
  }

  return updateCrew(crew, data)
}

export function transformCrewIntoAlien(crew) {
  const data = {
    name: getRandomName('alienname'),
    icon: 'user-alien',
    favorite_food: getRandomName('eyefood'),
    species: settings.SPECIES_GREYS
  }

  return updateCrew(crew, data)
}

export function transformCrewIntoCowboy(crew) {
  const data = {
    icon: 'user-cowboy'
  }

  return updateCrew(crew, data)
}

export function createCyborgCrew(ship) {
  const crewData = createCrewWeapons(ship.id)
  crewData.name = getRandomUniqueCrewName(ship, 'robotname')
  crewData.title = 'Killer Cyborg'
  crewData.icon = 'user-robot'
  crewData.favorite_food = getRandomName('robotfood')
  crewData.species = settings.SPECIES_ROBOT

  // Bump up weapons skill on Cyborg
  crewData.weapons = skillIncrease(crewData.weapons)
  crewData.weapons = skillIncrease(crewData.weapons)

  addCrew(crewData)
  addMessage(`${crewData.name} the killer cyborg has joined your crew.`, 'gain-message')
}

export function createCrewReplacement(position, ship) {
  var crewData = null

  switch (position) {
    case settings.POSITION_DOCTOR:
      crewData = createCrewDoctor(ship.id)
      break

    case settings.POSITION_MECHANIC:
      crewData = createCrewMechanic(ship.id)
      break

    case settings.POSITION_WEAPONS:
      crewData = createCrewWeapons(ship.id)
      break

    case settings.POSITION_PILOT:
      crewData = createCrewPilot(ship.id)
      break

    case settings.POSITION_COOK:
      crewData = createCrewCook(ship.id)
      break

    case settings.POSITION_NAVIGATOR:
      crewData = createCrewNavigator(ship.id)
      break

    case settings.POSITION_SCIENTIST:
      crewData = createCrewScientist(ship.id)
      break
  }

  crewData.name = getRandomUniqueCrewName(ship, 'robotname')
  crewData.icon = 'user-robot'
  crewData.favorite_food = getRandomName('robotfood')
  crewData.species = settings.SPECIES_ROBOT

  addCrew(crewData)
  addMessage(`You hire ${crewData.name} as your ship's ${position}.`, 'gain-message')
}

export function createBudgetCrewReplacement(position, ship, hired = true) {
  var crewData = null

  switch (position) {
    case settings.POSITION_DOCTOR:
      crewData = createCrewDoctor(ship.id)
      break

    case settings.POSITION_MECHANIC:
      crewData = createCrewMechanic(ship.id)
      break

    case settings.POSITION_WEAPONS:
      crewData = createCrewWeapons(ship.id)
      break

    case settings.POSITION_PILOT:
      crewData = createCrewPilot(ship.id)
      break

    case settings.POSITION_COOK:
      crewData = createCrewCook(ship.id)
      break

    case settings.POSITION_NAVIGATOR:
      crewData = createCrewNavigator(ship.id)
      break

    case settings.POSITION_SCIENTIST:
      crewData = createCrewScientist(ship.id)
      break
  }

  // Reduce attributes
  crewData.piloting = crewData.piloting - Math.floor(Math.random() * 2)
  crewData.mechanics = crewData.mechanics - Math.floor(Math.random() * 2)
  crewData.navigation = crewData.navigation - Math.floor(Math.random() * 2)
  crewData.medical = crewData.medical - Math.floor(Math.random() * 2)
  crewData.weapons = crewData.weapons - Math.floor(Math.random() * 2)
  crewData.cooking = crewData.cooking - Math.floor(Math.random() * 2)
  crewData.science = crewData.science - Math.floor(Math.random() * 2)

  // Reduce health
  crewData.health = Math.floor(Math.random() * 70) + 30
  crewData.morale = -1

  crewData.name = getRandomUniqueCrewName(ship, 'oldrobotname')
  crewData.icon = 'user-robot'
  crewData.favorite_food = getRandomName('robotfood')
  crewData.species = settings.SPECIES_ROBOT

  const droidDescription = getRandomName('droid-condition')

  addCrew(crewData)

  if (hired) {
    addMessage(`You hire the ${droidDescription} droid, ${crewData.name} as your ship's ${position}.`, 'gain-message')
  } else {
    addMessage(`The ${droidDescription} droid, ${crewData.name} joins your crew as the ${position}.`, 'gain-message')
  }
}

export function removeDeadCrew(ship) {
  const deadCrew = getDeadCrewMembers(ship)

  deadCrew.forEach((crew) => {
    updateCrew(crew, { ship_id: 0 })
  })
}

export function crewIconNameToPosition(iconName) {
  switch (iconName) {
    case settings.POSITION_ICON_COOK:
      return settings.POSITION_COOK
    case settings.POSITION_ICON_DOCTOR:
      return settings.POSITION_DOCTOR
    case settings.POSITION_ICON_MECHANIC:
      return settings.POSITION_MECHANIC
    case settings.POSITION_ICON_PILOT:
      return settings.POSITION_PILOT
    case settings.POSITION_ICON_WEAPONS:
      return settings.POSITION_WEAPONS
    case settings.POSITION_ICON_SCIENTIST:
      return settings.POSITION_SCIENTIST
    case settings.POSITION_ICON_NAVIGATOR:
      return settings.POSITION_NAVIGATOR
  }
}

export function getSpecialTitleByName(name, currentTitle) {
  switch (name) {
    // Seinfeld
    case 'Jerry':
      return 'Comedian'
    case 'George':
      return 'Architect'
    case 'Newman':
      return 'Mailman'
    case 'J. Peterman':
      return 'President'

    // Lost
    case 'Jack':
      return 'Survivor'
    case 'Kate':
      return 'Survivor'
    case 'Sawyer':
      return 'Survivor'
    case 'Sayid':
      return 'Survivor'
    case 'Hurley':
      return 'Survivor'
    case 'Sun':
      return 'Survivor'
    case 'Jin':
      return 'Survivor'
    case 'Claire':
      return 'Survivor'
    case 'Rose':
      return 'Survivor'
    case 'Shannon':
      return 'Survivor'
    case 'Michael':
      return 'Survivor'
    case 'John':
      return 'Survivor'
    case 'Boone':
      return 'Survivor'
    case 'Charlie':
      return 'Drive Shaft Guitarist'
    case 'Bernard':
      return 'Survivor'
    case 'Vincent':
      return 'Dog'
    case 'Ben':
      return 'Other'
    case 'Richard':
      return 'Other'
    case 'Jacob':
      return 'Other'
    case 'Juliet':
      return 'Other'

    // Back to the Future
    case 'Strickland':
      return 'Principal'
    case 'Einstein':
      return 'Dog'

    // Star Wars
    case 'Luke':
      return 'Jedi'
    case 'Leia':
      return 'Princess'
    case 'Han':
      return 'Smuggler'
    case 'Obi-Wan':
      return 'Jedi Master'
    case 'Yoda':
      return 'Jedi Master'
    case 'C-3PO':
      return 'Protocol Droid'
    case 'R2-D2':
      return 'Astro Droid'

    default:
      return currentTitle
  }
}

export function hasFullCrew(ship) {
  // Not a perfect check since sometimes things (robots/aliens/etc) can join the crew
  const crew = getLivingCrewMembers(0, ship)
  return crew.length >= 7
}

export function calculateSpaceBallInjuryChance(game) {
  switch (game.difficulty) {
    case settings.GAME_DIFFICULTY_EASY:
      return settings.SPACEBALL_INJURY_PERCENTAGE_EASY
    case settings.GAME_DIFFICULTY_NORMAL:
      return settings.SPACEBALL_INJURY_PERCENTAGE_NORMAL
    case settings.GAME_DIFFICULTY_HARD:
      return settings.SPACEBALL_INJURY_PERCENTAGE_HARD
    case settings.GAME_DIFFICULTY_EXPERT:
      return settings.SPACEBALL_INJURY_PERCENTAGE_EXPERT
    case settings.GAME_DIFFICULTY_FOREVER:
      return settings.SPACEBALL_INJURY_PERCENTAGE_FOREVER
  }
}

export function applySpaceBallInjury(crewMember) {
  const injuryAmount = -getRandomBetween(5, 15)
  const bodyPart = getRandomName('bodypart')

  addMessage(`${crewMember.name} injured their ${bodyPart} while playing Space Ball.`, 'lose-message')
  updateCrewHealth(crewMember, injuryAmount)
}

export function calculationSpaceBallMoraleBoost() {
  return Math.floor(Math.random() * 3) == 3 ? 2 : 1
}

export function getRandomUniqueCrewName(ship, nameType) {
  const crewNames = []

  ship.crew_members.forEach((crew) => {
    crewNames.push(crew.name)
  })
  return getRandomNameExcluding(nameType, crewNames)
}

export function recordCrewSkillCheck(crewMember, successful, difficultyLevel) {
  const success_count = successful ? crewMember.success_count + 1 : crewMember.success_count
  updateCrew(crewMember, { checks_performed : crewMember.checks_performed + 1, success_count: success_count })
}