import { Model } from '@vuex-orm/core'
import Ship from './Ship'
import Disease from './Disease'

export default class Crew extends Model {
  static entity = 'crews'

  static fields () {
    return {
      id: this.uid(),
      name: this.string(''),
      position: this.string(''),
      title: this.string(''), // for display purposes at this time
      icon: this.string('user'),

      // Skills
      piloting: this.number(0),
      mechanics: this.number(0),
      navigation: this.number(0),
      medical: this.number(0),
      weapons: this.number(0),
      cooking: this.number(0),
      science: this.number(0),

      // stats
      status: this.string(''), // Statuses: alive, dead, panic, rage
      has_disease: this.boolean(false),
      disease_id: this.attr(null),
      disease: this.belongsTo(Disease, 'disease_id'), // Name of disease they are infected with if any
      disease_duration: this.number(0),
      drunkenness: this.number(0),
      hunger: this.string(''), // full, peckish, hungry, starving
      days_since_last_meal: this.number(0),
      health: this.number(0), // current health level (percentage)
      morale: this.number(0), // current morale level. Between -5 and 5. Negative numbers are bad
      xp: this.number(0),
      level: this.number(0),
      cost: this.number(0),  // Daily cost of having this crew member
      ship_id: this.number(null),
      ship: this.belongsTo(Ship, 'ship_id'),

      home_planet: this.string(''),
      favorite_food: this.string(''),
      species: this.string('human'),
      checks_performed: this.number(0),
      success_count: this.number(0),
    }

  }
}
