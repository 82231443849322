<template>
  <div class="console game-credits with-border with-border-radius text-center" style="text-align: left;color: white;">
    <h2 class="green">Space Awesome - Release Notes</h2>

    <h4 class="release-header">v1.0.5 - March 21, 2025</h4>
    <ul class="margin-bottom-2xl">
      <li>Added Crew Database to Navigation Terminal</li>
      <li>Added Crew Performance to Bio</li>
      <li>Added new secret to start screen</li>
    </ul>

    <h4 class="release-header">v1.0.4 - October 24, 2024</h4>
    <ul class="margin-bottom-2xl">
      <li>Added new communicator code</li>
      <li>Updated FontAwesome Crew</li>
    </ul>

    <h4 class="release-header">v1.0.3 - December 21, 2022</h4>
    <ul class="margin-bottom-2xl">
      <li>Added release notes</li>
      <li>Made improvements to Wormhole event</li>
      <li>Added missing achievements</li>
      <li>Added new secret code to start screen</li>
      <li>Added help message about game difficulty</li>
    </ul>

    <h4 class="release-header">v1.0.0 - May 27, 2022</h4>
    <ul class="margin-bottom-2xl">
      <li>Initial Release</li>
    </ul>

    <div class="margin-top-3xl">
      <button @click="gotoRoom('gamestart')" class="blue">Close <i class="fas fa-circle-xmark"></i> </button>
    </div>
  </div>
</template>

<script>
import GameMixin from '../../mixins/GameMixin'

export default {
  name: "ReleaseNotes",

  mixins: [
    GameMixin
  ],

  methods: {
    gotoRoom (room) {
      this.clearMessages()
      this.changeDisplay(room)
    }
  }
}
</script>
