<template>
  <div class="database column-12">
    <div class="wrap-comm-screen">
      <div class="comm-screen with-border with-border-radius padding-md">
        <div class="output with-border with-border-radius padding-sm text-left">
          <div class="inner-key-panel">

            <div v-if="this.display == 'menu'">
              <h3 class="terminal-heading">Navigation Terminal</h3>

              <ul>
                <li><a href="#" @click.prevent="switchView('mission')" class="terminal-menu">Mission Status</a></li>
                <li><a href="#" @click.prevent="switchView('planet')" class="terminal-menu">Planet Database</a></li>
                <li><a href="#" @click.prevent="switchView('crew')" class="terminal-menu">Crew Database</a></li>
                <li><a href="#" @click.prevent="switchView('help')" class="terminal-menu">System Help</a></li>
                <!-- <li><a href="#" @click.prevent="switchView('maze')" class="terminal-menu">Thaze's Maze</a></li> -->
                <li><a href="#" @click.prevent="switchView('system')" class="terminal-menu">System Info</a></li>
              </ul>

            </div>

            <div v-else-if="this.display == 'mission'">
              <mission-info @switchView="switchView" />
            </div>

            <div v-else-if="this.display == 'maze'">
              <thaze-maze @switchView="switchView" />
            </div>

            <div v-else-if="this.display == 'help'">
              <system-help @switchView="switchView" />
            </div>

            <div v-else-if="this.display == 'crew'">
              <crew-database @switchView="switchView" />
            </div>

            <div v-else-if="this.display == 'planet'">
              <planet-database @switchView="switchView" />
            </div>

            <div v-else-if="this.display == 'system'">
              <terminal-info @switchView="switchView" terminal-id="1032" />
              <icon-footer icon1="cake-candles" icon2="gift" icon3="money-bill-1" />
            </div>

          </div>
        </div>

        <div class="button-panel text-center">
          <span class="panelbutton panelbutton-yellow"></span>
          <span class="panelbutton panelbutton-white"></span>
          <span class="panelbutton panelbutton-green flashing"></span>
          <span class="panelbutton panelbutton-red "></span>
          <span class="panelbutton panelbutton-blue flashing-slow"></span>
          <span class="panelbutton panelbutton-green"></span>
          <span class="panelbutton panelbutton-yellow flashing-fast"></span>
          <span class="panelbutton panelbutton-white flashing-slow"></span>
          <span class="panelbutton panelbutton-blue"></span>
          <span class="panelbutton panelbutton-red"></span>
        </div>

        <span style="color: var(--white);">- Teletraan IV -</span>
      </div>
    </div>
  </div>
</template>

<script>
  import TerminalInfo from './TerminalInfo.vue'
  import IconFooter from './IconFooter.vue'
  import ThazeMaze from './ThazeMaze.vue'
  import PlanetDatabase from './PlanetDatabase.vue'
  import CrewDatabase from './CrewDatabase.vue'
  import MissionInfo from './MissionInfo.vue'
  import GameMixin from '../../mixins/GameMixin'
import SystemHelp from './SystemHelp.vue'

  export default {
    name: "NavigationMenu",

    mixins: [
      GameMixin
    ],

    components: {
      TerminalInfo,
      IconFooter,
      ThazeMaze,
      CrewDatabase,
      PlanetDatabase,
      MissionInfo,
      SystemHelp
    },

    data ()
    {
      return {
        display: 'menu'
      }
    },

    methods: {
      switchView (view) {
        this.display = view
      },

      returnToMenu () {
        this.switchView('menu')
      }
    }
  }
</script>
